@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css");
@font-face {
  font-family: "Satoshi";
  src: url(../fonts/OTF/Satoshi-Regular.otf);
}
:root {
  --toastify-icon-color-success: #2c6c3f;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  max-width: 1294px;
}
body {
  background-color: #0a0b1f !important;
  background-image: url(../img/back-ground.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  font-family: "Satoshi";
  height: 100%;
}
header {
  padding: 50px 20px;
}
.btnHeader {
  text-align: right;
}
.btnSection {
  justify-content: space-between;
}
.btnContract {
  position: relative;
  background-color: #f7941e;
  border-radius: 100px;
  padding: 16px 39px 16px 39px;
  border: none;
  font-family: "Satoshi";
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0.03em;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  color: rgba(10, 11, 31, 1);
}
.btnContract:hover {
  text-decoration: none;
  color: rgba(10, 11, 31, 1);
}
.content-section {
  display: flex;
  /* height: 100vh; */
  justify-content: center;
  align-items: center;
  padding: 220px 0px;
}
.searchBarSection {
  background-color: rgba(10, 11, 31, 0.6);
  border: 1px solid rgba(13, 169, 252, 0.3);
  /* margin-top: 220px; */
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.1);
}
#searchBar {
  padding: 15px 0;
  opacity: 0.7;
  width: 100%;
  height: 50px;
  border: none;
}
.form-control:focus {
  box-shadow: none !important;
}
input::placeholder {
  color: rgba(168, 174, 176, 0.98) !important;
}
.searchSection i {
  pointer-events: none;
  color: white;
}
.input-group {
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  border-radius: 10px;
  padding: 10px 0 10px 10px;
}
.form-control {
  color: #fff;
  border: 0 !important;
}
.form-control:read-only {
  background-color: #fff !important;
  cursor: not-allowed;
}
.form-control:focus {
  box-shadow: none;
  border: none;
}
.input-group input,
.form-control:focus,
input:active,
.input-group-text {
  background-color: transparent !important;
  align-items: center !important;
  /* border: 0 !important; */
}
.input-group-text {
  border: 0 !important;
}
select {
  color: #ffffff;
  border: 0;
  padding: 10px;
}

.dropdown-toggle {
  border: 0 !important;
  border-left: 1px solid white;
  border-radius: 0 !important;
  padding: 0 55px 0 20px !important;
  background-color: transparent !important;
  color: #fff !important;
  font-size: 18px !important;
}
.dropdown-toggle::after {
  position: absolute;
  right: 15px;
  top: 45%;
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
}
.dropdown-toggle:hover {
  border-left: 1px solid white;
  background-color: transparent;
}
.drpSpanText {
  color: rgba(168, 174, 176, 1);
  font-size: 12px;
  position: relative;
}
.dropdown-toggle:active {
  background-color: transparent;
}
.searchText {
  color: rgba(168, 174, 176, 0.97);
  margin-top: 40px;
  margin-bottom: 0;
  font-family: "Satoshi";
  font-size: 18px;
  font-weight: 300;
  line-height: 24.3px;
}
#noDots {
  list-style-type: none;
  display: none;
  padding: 0 80px 0 20px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  margin-top: 5px;
}
.searchMenu {
  padding: 15px 0;
  overflow-x: scroll;
  text-align: left;
  border-bottom: 1px solid white;
  color: rgba(255, 255, 255, 1);
}
.searchMenu:last-child {
  border-bottom: 0 !important;
}
.searchMenu:not(#noDots .searchMenu) {
  border-bottom: 1px solid white;
}
.image_searchManu {
  margin-right: 11px;
}
.dropdown-item {
  padding: 10px !important;
  border-bottom: 1px solid rgba(194, 205, 210, 1) !important;
  cursor: pointer;
}
.dropDownMenu {
  background-color: rgb(255, 255, 255, 1) !important;
  padding: 10px 25px !important;
  border-radius: 0px, 0px, 10px, 10px;
}
.dropDownMenu:active {
  transform: translate(808px, 62px);
}
.textSearchMenu {
  color: white;
}
.textColordrp {
  color: rgba(10, 11, 31, 1);
  font-size: 14px;
}
.search-result .textColordrp {
  font-size: 18px;
  color: #fff;
}
.search-result .drpSpanText {
  font-size: 16px;
}
footer {
  justify-content: space-between;
  /* margin-top: 244px; */
  background-color: #ffffff33;
  padding: 40px 20px;
  border-radius: 9px 9px 0px 0px;
  left: 0;
  bottom: 0;
  width: 100%;
}
.footerMenu {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: end;
}
.textFooter a {
  font-family: "Satoshi";
  font-size: 22px;
  font-weight: 500;
  line-height: 29.7px;
  margin: 5px 28px;
  color: #ffffff;
}
a {
  text-decoration: none !important;
}
.textFooter a:hover {
  text-decoration: none;
}
.sectionResult {
  margin-top: 40px !important;
}
.input-group-result {
  background-color: rgba(255, 255, 255, 1);
  padding: 10px;
}
.input-control-result {
  border-right: 1px solid black;
  margin-right: 7px;
  font-family: "Satoshi";
  font-size: 22px;
  font-weight: 500;
  line-height: 29.7px;
  text-align: left;
  color: rgba(10, 11, 31, 1);
}
ul:last-child {
  border-bottom: none;
}
.fa-solid {
  color: black;
}
.input-group-text {
  padding: 10px 10px 10px 10px !important;
}
.btn-check:active + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
  background-color: transparent !important;
  border-color: #fff !important;
  box-shadow: unset;
}
.h-70 {
  height: 70px;
}
/* / section_form / */
.form-bg {
  padding: 35px;
}
.chainLabel {
  text-align: left;
}
.feeLabel {
  text-align: left;
}
.btnBorder {
  border-left: none;
  padding-left: 15px;
}
.btnBorder:hover {
  border-left: none;
  background-color: transparent;
}
label {
  font-family: "Satoshi";
  font-size: 22px;
  font-weight: 500;
  line-height: 29.7px;
  text-align: left;
  color: rgba(255, 255, 255, 0.97);
  margin-bottom: 12px;
}
.input-control-fee {
  padding: 20px 10px !important;
  background-color: rgba(255, 255, 255, 0.3) !important;
  border: none !important;
  border-radius: 10px !important;
  color: rgba(255, 255, 255, 1) !important;
  font-family: Satoshi;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  text-align: left;
}
.drpWallet {
  padding: 12px 0;
}
.addressLabel {
  text-align: left;
  margin-top: 30px !important;
}
.btnSubmit {
  background-color: rgba(247, 148, 30, 1);
  border-radius: 103px;
  padding: 16px 39px;
  margin-top: 35px;
}
/* / walletResult / */
.walletResultText {
  font-family: "Satoshi";
  font-size: 14px;
  font-weight: 500;
  line-height: 18.9px;
  letter-spacing: 0.05em;
  text-align: left;
  color: rgba(10, 11, 31, 1);
  margin-left: 5px;
}
.d-flex-bg {
  background-color: rgba(10, 11, 31, 0.06);
  padding: 15px;
  margin: -10px 0 -10px -10px;
}
.spanText {
  font-family: "Satoshi";
  font-size: 12px;
  font-weight: 500;
  line-height: 16.2px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #535e70 !important;
  padding: 0;
}

.input-control-fee:focus {
  background-color: rgba(255, 255, 255, 0.3) !important;
  color: #fff !important;
}
.drpWallet .dropdown-menu.show {
  width: 100%;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #e9ecef;
  color: #000;
}
/* / Model-popup / */
.modal-content {
  padding: 30px 40px;
  background-color: rgba(255, 255, 255, 1) !important;
  border-radius: 20px !important;
}
.modal-header {
  justify-content: center;
  border-bottom: none !important;
  padding: 0 !important;
  margin-bottom: 26px;
}
.modal-body {
  padding: 0 !important;
}
.modalMenu {
  align-items: center;
  background-color: rgba(10, 11, 31, 0.08);
  padding: 19px 13px;
  border-radius: 10px;
  cursor: pointer;
}
.modalText {
  font-family: Satoshi;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: rgba(10, 11, 31, 1);
}
.wallet_img {
  margin-right: 8px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 6px;
  height: 54px;
  width: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.badge-warning {
  background-color: rgb(247, 148, 30, 0.1) !important;
  color: #f7941e !important;
}

input.form-control.input-control-result:focus,
input.form-control.input-control-result:active {
  border-right: 1px solid !important;
}
.btn-check:active + .btn-outline-secondary:focus,
.btn-check:checked + .btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus {
  box-shadow: unset !important;
}
.border-color {
  border-left: 1px solid white !important;
}

.scrollbar-thin {
  scrollbar-width: thin;
  scrollbar-color: #0d132a #f5f5f500;
}
.scrollbar-thin::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: #f5f5f5;
  border-radius: 10px;
}
.scroll-table {
  max-height: none;
  overflow-y: auto;
}
.scroll-table::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}
.scroll-table::-webkit-scrollbar-thumb {
  background-color: #f4f4f400;
  border-radius: 10px;
}
.scroll-main {
  max-height: 100vh !important;
  overflow-y: auto !important;
}
.cursor-pointer {
  cursor: pointer;
}
.inputText {
  color: white !important;
  font-size: 19px;
}
.linkPage {
  background-color: white;
  background-image: none;
}
.headerLink {
  background-color: #0a0b1f;
}
.sectionLink {
  background-color: white;
}
.footerLink {
  background-color: #0a0b1f;
  /* / position: /fixed; / */
}
.headerAbout {
  font-size: 50px;
  font-family: "Satoshi";
}
.textHeader {
  font-size: 22px;
  font-family: "Satoshi";
}
.qr-code-icon .textAddress {
  display: none;
  position: absolute;
  margin-top: 16px;
  /* left: 50%; */
  transform: translateX(-50%);
  background: white;
  padding: 5px 10px;
  border: 1px solid #ccc;
  z-index: 10;
  /* width: 300px; */
  border-radius: 5px;
  border: none;
  background-color: rgba(255, 255, 255, 1);
}

.qr-code-icon:hover .textAddress {
  display: block;
}

.inputText {
  color: white;
  font-size: 19px;
}

.spanText {
  padding: 0;
}

/* / buton-hover / */
.btnHover {
  display: none;
  position: absolute;
  margin-top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 5px 15px;
  border: 1px solid #ccc;
  z-index: 10;
  font-size: 12px;
  /* / width: 280px; / */
  border-radius: 5px;
  border: none;
  background-color: rgba(255, 255, 255, 1);
}
.btnContract:hover .btnHover {
  display: block;
}
body:has(.contentPage){
  background-color: #fff !important;
  background-image: none;
  /* height: 100%; */
}
.btn-close{
  position: absolute;
    top: 10px;
    right: 11px;
}
.btn-close:focus{
  box-shadow: none !important;
}
@media screen and (max-width: 1600px) and (min-width: 768px) {
  .btnHover {
    left: 0;
  }
}
@media screen and (min-height: 925px) {
  footer {
    position: fixed;
  }
  /* .content-section {
      min-height: 1049px;
      height: 100vh;
      padding: 0;
  } */
}
@media screen and (max-width: 992px) {
  .content-section {
    padding: 80px 20px;
  }
  footer {
    padding: 20px;
  }
  .dropdown-toggle::after {
    right: 5px;
  }
  .dropdown-toggle:not(.btndropDown) {
    padding: 0 5px !important;
  }
}
@media screen and (max-width: 769px) {
  .btnArrow::after {
    margin-left: 0.2em;
  }
  #searchBar {
    width: 80%;
    padding: 5px;
  }
  .input-group:not(.input-group.drpWallet) {
    padding: 10px;
  }
  .searchSection .dropdown-toggle {
    padding: 10px !important;
    width: 100%;
    border-left: 0 !important;
    border-top: 1px solid #fff !important;
  }
  .dropdown-toggle:hover {
    border-left: 0 !important;
  }
  .dropdown-menu.show {
    width: 98%;
  }
  #noDots {
    padding: 20px;
  }
  .input-control-result,
  .textFooter a {
    font-size: 20px;
  }
  .btnContract {
    font-size: 18px;
  }
  .search-result .textColordrp {
    font-size: 16px;
  }
  .search-result .drpSpanText {
    font-size: 14px;
  }
  .border-color {
    border-left: none !important;
  }
}
/* @media screen and (min-height:660px) {
  .footerLink{
      position: fixed;
  }
} */
@media (max-width: 425px) {
  .instatoken_logo {
    height: 30px;
  }
  .content-section {
    padding: 80px 0;
  }
  .form-bg,
  .searchBarSection {
    padding: 35px 10px;
  }
  .btnSection {
    padding: 0 8px;
  }
  .qr-code-icon:hover .qr-code {
    left: -34px !important;
  }
}

/* DotDotDotLoader.css */
.dot-dot-dot-loader {
  font-size: 1.5em;
  text-align: center;
}

.input-group-text {
  position: relative;
}

.qr-code-icon .qr-code {
  display: none;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 5px;
  border: 1px solid #ccc;
  z-index: 10;
}

.qr-code-icon:hover .qr-code {
  display: flex;
}

.Toastify__close-button--light {
  color: #fff !important;
  opacity: 1 !important;
  background: #f44336;
  border-radius: 100%;
  padding: 0 4px 0px 6px;
}
.Toastify__toast--success .Toastify__close-button--light{
  background: #2C6C3F;
}

.Toastify__toast--fade {
  transition: opacity 0.5s ease-in-out;
}

.Toastify__toast--fade.Toastify__toast--visible {
  opacity: 1;
}

.Toastify__toast--fade.Toastify__toast--hidden {
  opacity: 0;
}

.p-record{
  color: rgb(247 148 30);
    padding:0 0 5px !important;
    font-size: 18px;
    /* / margin-bottom: 20px !important; / */
    border-bottom: 1px solid;
}